import React, { Fragment, useState } from "react"
import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import Theme from "../theme/theme"
import GlobalStyle from "../theme/global-style"
import MenuScreen from "../components/MenuScreen"
import Navigation from "../components/Navigation"
import Section from "../components/Section"
import SplitSection from "../components/SplitSection"
import Footer from "../components/Footer"
import { over } from "../assets/image-urls"
import { P } from "../theme/elements"

const pageContent = {
  title: "Atelier Stöpler — Op Maat Gemaakte Sieraden",
}

const InnerSection = styled.div`
  padding: 0;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
  font: 500 100%/1.5 system-ui;

  @media screen and (max-width: 650px) {
    display: block;
    font-size: 80%;
    p {
      text-justify: distribute;
    }
  }
`

const Img = styled.img`
  max-width: 100%;
  height: auto;
  min-height: 500px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ffc958;
  margin: 30px 0;
  box-shadow: 0 0 200px -90px rgb(255, 189, 141);
`

const Text = styled.div`
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  @media screen and (max-width: 650px) {
    position: relative;
    margin: 0;
    padding: 20px 4px;
  }
`

const ItemList = styled.ul`
  list-style-type: disc;
`

const Item = styled.li`
  margin-left: 16px;
  padding: 14px;
  font-weight: 300;
  font-size: 16px;
`

const OurAtelier = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageContent.title}</title>
        <link rel="canonical" href="https://atelierstopler.nl" />
      </Helmet>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <MenuScreen isOpen={isOpen} closeMenu={closeMenu} />
        {isOpen ? null : (
          <Fragment>
            <Navigation openMenu={openMenu} isOpen={isOpen} />
            <Section title="Over Atelier Stöpler">
              <InnerSection>
                <Text>
                  <P>
                    Kim Stöpler is als goudsmid het gezicht achter het familie
                    bedrijf Atelier Stöpler.
                  </P>
                  <P>
                    In 2015 studeerde ze als Goudsmid en Nederlands
                    Edelsteenkundige (register Dutch Gemmological Institute) af
                    aan de Vakschool Schoonhoven. Na haar studie is ze gaan
                    werken bij Liesbeth Quaak goud- en zilversmid waar ze haar
                    kennis over verschillende technieken heeft uitgebreid.
                    Hiernaast heeft ze ook jaren gewerkt bij Atelier ’t Sieraad
                    in Amsterdam, waar de liefde voor het ontwerpen is ontstaan.
                    Vanaf jonge leeftijd heeft ze zo veel uren gemaakt en zich
                    het vak eigen gemaakt.
                  </P>
                  <P>
                    Haar stijl laat zich definiëren door sieraden die generaties
                    lang meegaan. Ze ontwerpt elegante, krachtige designs waar
                    kwaliteit voorop staat. Om deze sieraden te maken gebruikt
                    ze klassieke en moderne technieken, van het smeden van
                    materiaal tot het ontwerpen van designs in 3D programma’s.
                    Moderne sieraden met een traditioneel randje. Met unieke
                    edelstenen die een verhaal of je persoonlijkheid laten
                    spreken. Van 14 kt geelgoud tot zilver.
                  </P>
                </Text>
                <Img src={over} alt="Cursus 1" />
                <Text>
                  <P>
                    Kim is een voorstander van het hergebruiken van eigen goud,
                    steeds vaker wordt “oud” goud nieuw leven ingeblazen.
                  </P>
                  <P>U kunt bij Kim terecht voor het volgende:</P>
                  <ItemList>
                    <Item>
                      Ontwerpen en vervaardiging van sieraden naar wens
                    </Item>
                    <Item>Verandering / aanpassing van bestaande sieraden</Item>
                    <Item>Reparatie van sieraden en horloges</Item>
                    <Item>Vervangen batterijen van horloges</Item>
                    <Item>
                      Gedenksieraden bijvoorbeeld het vermaken van oude
                      trouwringen en/of een as-verwerking
                    </Item>
                    <Item>Trouwsieraden</Item>
                    <Item>3D ontwerpen</Item>
                    <Item>Inruilen goud en zilver</Item>
                    <Item>Occasions verkoop en inkoop</Item>
                    <Item>
                      Basis- en vervolg cursus, Cursus op maat en zelf uw
                      trouwringen maken.
                    </Item>
                    <Item>Werkbanken te huur! (met eventuele begeleiding)</Item>
                  </ItemList>
                  <P>
                    In alle sieraden die zij vervaardigd, zet ze haar
                    meesterteken. Dit is een geregistreerd
                    verantwoordelijkheidsteken waaraan u de identiteit van de
                    goudsmid kunt herleiden. U kunt het zien als haar merk.
                  </P>
                </Text>
              </InnerSection>
            </Section>
            <SplitSection />
            <Footer />
          </Fragment>
        )}
      </ThemeProvider>
    </main>
  )
}

export default OurAtelier
